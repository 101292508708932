import request from '../../libs/axios'


export function getBranchClientReports(params) {
  return request({
    url: '/api/get-branch-clients-report',
    method: 'get',
    params,
  })
}

export function getOperatorDailyReports(params) {
  return request({
    url:'/api/get-operator-daily',
    method:'get',
    params,
  })
}

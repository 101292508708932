<template>
  <div class="w-100">
    <b-card>
      <b-row>
        <b-col md="3">
          <v-select :options="branchs" label="name" v-model="branch_id" ></v-select>
        </b-col>
        <b-col md="3">
          <input type="date" v-model="filters.date" class="form-control">
        </b-col>
        <b-col md="2">
          <b-button variant="warning" size="sm" @click="searchFilter">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
   <div class="mt-3" v-for="(val,index) in reports" :key="index">
     <b-card v-if="val.data.series.length">
       <b-row>
         <b-col md="9" class="mt-4">
           <div class="w-100">
             <h3>{{val.name}}</h3>
           </div>
           <vue-apex-charts class="w-75" type="pie" :options="getOptions(val.data.labels)" :series="val.data.series" />
         </b-col>
         <b-col md="3" class="mt-5">
           <div v-for="(item, index) in val.data.labels" class="w-100 d-flex justify-content-between my-1" :key="index">
            <span>
              <h5>{{item}}</h5>
            </span>
             <span>
               <b-badge variant="light-success">
                  <h5>{{val.data.series[index]}}</h5>
                </b-badge>
               </span>
           </div>
         </b-col>
       </b-row>
     </b-card>
   </div>
  </div>
</template>

<script>
import { getOperatorDailyReports } from '@/api/reports/branch'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { getBranches } from '@/api/branchs/branchs'

const labelColors = [
  '#826af9',
  '#d2b0ff',
  '#f8d3ff',
  '#7eefc7',
  '#06774f',
  '#ffe700',
  '#00d4bd',
  '#826bf8',
  '#2b9bf4',
  '#FFA1A1',
  '#a4f8cd',
  '#60f2ca',
  '#2bdac7'
];

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  name: 'OperatorDaily',
  components: {
    VueApexCharts,
    vSelect,
  },
  data() {
    return {
      reports: [],
      paymentReport:[],
      branchClients: [],
      branchs: [],
      filters: {
        date: null,
        branch_id: null,
      },
      branch_id: null,
    }
  },
  mounted() {
    this.fetchReports();
    getBranches().then(res => {
      this.branchs = res.data.data;
    })
  },
  methods: {
    fetchReports() {
      getOperatorDailyReports(this.filters).then(res => {
        this.reports = res.data.data;
      })
    },
    searchFilter() {
      this.filters.branch_id = this.branch_id ? this.branch_id.id : null;
      this.fetchReports();
    },
    getOptions(labels) {
      return    {
        legend: {
          show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
        },
        colors: labelColors,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                    name: {
                  fontSize: '2rem',
                      fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                      fontFamily: 'Montserrat',
                      formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}`
                  },
                },
              },
            },
          },
        },
        labels: labels,
            responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    }
  }
}
</script>

<style scoped>

</style>
